import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  lang?: string;
  ogImageSrc?: string;
  meta?: {
    property: string;
    content: string;
  }[];
  title?: string;
}

function determineOgImageUrl(ogImageSrc?: string) {
  if (!ogImageSrc) {
    return null;
  }

  if (ogImageSrc.toLowerCase().startsWith('http')) {
    return ogImageSrc;
  }

  return 'https://hellorealworld.com' + ogImageSrc;
}

const SEO = ({ description, lang, ogImageSrc, meta, title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const computedTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={computedTitle}
      titleTemplate={title ? `%s | ${site.siteMetadata.title}` : `%s`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: computedTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content:
            determineOgImageUrl(ogImageSrc) ||
            'https://hellorealworld.com/assets/ogimage.png',
        },
        {
          property: 'twitter:image',
          content:
            determineOgImageUrl(ogImageSrc) ||
            'https://hellorealworld.com/assets/twitterimage.png',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: computedTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta || [])}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
