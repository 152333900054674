import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { INNER_CONTENT_MAX_WIDTH } from '../../theme';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${INNER_CONTENT_MAX_WIDTH}px;
  padding: 0 1rem;
`;

export default ({ children }: { children: ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
