import React from 'react';
import styled from 'styled-components';
import { ACCENT_COLOR } from '../../theme';
import { Link } from 'gatsby';

interface Props {
  homepageDescription?: string;
}

const TitleLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    text-decoration: inherit;
    &:after {
      display: none;
    }
  }
`;

const HomepageTitle = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 1.8rem;
  color: #000;
  text-align: center;
  margin: 3rem 0 0.5rem 0;

  @media (min-width: 500px) {
    font-size: 3rem;
  }
`;

const HomepageSubtitle = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 1rem;
  color: #222;
  text-align: center;
  margin-bottom: 5rem;

  @media (min-width: 500px) {
    font-size: 1.2rem;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
    background-color: ${ACCENT_COLOR};
  }
`;

const Title = styled.div`
  position: relative;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 1.5rem;
  color: #000;
  margin: 1.5rem 0 3rem 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 5px;
    background-color: ${ACCENT_COLOR};
  }
`;

const titleText = (
  <TitleLink to="/">
    hello
    <span style={{ padding: '0 7px 0 4px', color: ACCENT_COLOR }}>real</span>
    world
  </TitleLink>
);

export default ({ homepageDescription }: Props) => {
  if (homepageDescription) {
    return (
      <>
        <HomepageTitle>{titleText}</HomepageTitle>
        <HomepageSubtitle>{homepageDescription}</HomepageSubtitle>
      </>
    );
  }

  return <Title>{titleText}</Title>;
};
