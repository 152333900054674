import { createGlobalStyle } from 'styled-components';

// export const ACCENT_COLOR = '#017A79';
export const ACCENT_COLOR = '#0F9B8E';

export const INNER_CONTENT_MAX_WIDTH = 760;

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    min-height: 100%;
  }

  html,
  body {
    font-family: 'Roboto Slab', serif;

    width: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media only screen and (max-width: 600px) {
    body {
      overflow-y: initial;
    }
  }

  a {
    position: relative;
    text-decoration: inherit;
    color: ${ACCENT_COLOR};
  }

  a:hover {
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      border-radius: 5px;
      background-color: ${ACCENT_COLOR};
    }
  }

  img {
    object-fit: cover;
  }

  p {
    white-space: pre-line; 
  }

  #___gatsby {
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 300px;
  }
`;
