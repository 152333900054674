import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '../../theme';
import { Helmet } from 'react-helmet';
import InnerContentWrapper from '../InnerContentWrapper';
import PageHeader from '../PageHeader';
import { Link } from 'gatsby';
import GithubLogo from './img/github.png';
import LinkedInLogo from './img/linkedin.png';
import TwitterLogo from './img/twitter.png';

interface Props {
  homepageDescription?: string;
  children: ReactNode;
}

const Toolbar = styled.header`
  width: 100%;
  background-color: #f2f2f2;
  padding: 1rem 0;
`;

const ToolbarList = styled.ul`
  font-weight: 300;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  li {
    font-size: 1rem;
    padding: 0 0 0 2rem;
  }
`;

const FooterLogo = styled.img`
  width: 18px;
  height: 18px;

  @media (min-width: 400px) {
    width: 24px;
    height: 24px;
  }
`;

const Footer = styled.footer`
  padding: 8rem 0 4rem 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    li {
      font-size: 1rem;
      padding: 0 0 0 1rem;
    }

    @media (min-width: 400px) {
      li {
        padding: 0 0 0 1.5rem;
      }
    }
  }
`;

export default ({ homepageDescription, children }: Props) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap"
          rel="stylesheet"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyles />
      <Toolbar>
        <InnerContentWrapper>
          <ToolbarList>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/categories">Categories</Link>
            </li>
          </ToolbarList>
        </InnerContentWrapper>
      </Toolbar>
      <InnerContentWrapper>
        <PageHeader homepageDescription={homepageDescription} />
        {children}
        <Footer>
          <ul>
            <li style={{ paddingLeft: 0, flex: 1 }}>
              © {new Date().getFullYear()}{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.twitter.com/richardgirges/"
              >
                Richard Girges
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.github.com/richardgirges"
              >
                <FooterLogo src={GithubLogo} />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/richardgirges/"
              >
                <FooterLogo src={LinkedInLogo} />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.twitter.com/richardgirges/"
              >
                <FooterLogo src={TwitterLogo} />
              </a>
            </li>
          </ul>
        </Footer>
      </InnerContentWrapper>
    </>
  );
};
